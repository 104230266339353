@import "_custom-properties.scss";

.site-branding {
	max-height: inherit;
	a.brand {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-decoration: none;
		color: inherit;
		max-height: inherit;
		img {
			display: block;
			//width:auto;
		}
	}
	.site-title {
		margin:0;
	}
	.site-description {
		margin: 0.4375em 0 0;
		word-wrap: break-word;
	}
	&.branding-layout-standard:not(.site-brand-logo-only) a.brand {
		img {
			margin-inline-end: 1em;
		}
	}
	&.branding-layout-standard-reverse a.brand {
		flex-direction: row-reverse;
		img {
			margin-inline-start: 1em;
		}
	}
	&.branding-layout-vertical {
		&.site-title-top a.brand {
			.site-title-wrap {
				order:-1;
			}
			img {
				margin-block-start: 1em;
				margin-block-end: 1em;
			}
		}
		a.brand {
			flex-direction: column;
			img {
				margin-block-end: 1em;
			}
		}
	}
	&.branding-layout-vertical-reverse a.brand {
		flex-direction: column-reverse;
		img {
			margin-block-start: 1em;
		}
	}
}
.site-header-section-center .site-branding {
	&.branding-layout-vertical, &.branding-layout-vertical-reverse {
		a.brand {
			text-align: center;
			img {
				margin-inline-start: auto;
				margin-inline-end: auto;
			}
		}
	}
}
@media screen and ( max-width: $tablet-minus-query) {
	.site-branding {
		&.branding-tablet-layout-standard:not(.site-brand-logo-only) a.brand {
			img {
				margin-inline-end: 1em;
			}
		}
		&.branding-tablet-layout-standard-reverse a.brand {
			flex-direction: row-reverse;
			img {
				margin-inline-end: 1em;
				margin-block-end:0;
				margin-block-start: 0;
			}
		}
		&.branding-tablet-layout-vertical {
			&.site-title-top a.brand {
				.site-title-wrap {
					order:-1;
				}
				img {
					margin-block-start: 1em;
					margin-block-end: 1em;
					margin-inline-end: 0;
					margin-inline-start: 0;
				}
			}
			a.brand {
				flex-direction: column;
				img {
					margin-block-end: 1em;
					margin-inline-end: 0;
					margin-inline-start: 0;
				}
			}
		}
		&.branding-tablet-layout-vertical-reverse a.brand {
			flex-direction: column-reverse;
			img {
				margin-block-start: 1em;
				margin-inline-end: 0;
				margin-inline-start: 0;
			}
		}
	}
}
@media screen and ( max-width: $phone-minus-query) {
	.site-branding {
		&.branding-mobile-layout-standard:not(.site-brand-logo-only) a.brand {
			flex-direction: row;
			img {
				margin-inline-end: 1em;
				margin-block-end:0;
				margin-block-start: 0;
			}
		}
		&.branding-mobile-layout-standard-reverse a.brand {
			flex-direction: row-reverse;
			img {
				margin-inline-start: 1em;
				margin-block-end:0;
				margin-block-start: 0;
			}
		}
		&.branding-mobile-layout-vertical {
			&.site-title-top a.brand {
				.site-title-wrap {
					order:-1;
				}
				img {
					margin-block-start: 1em;
					margin-block-end: 1em;
					margin-inline-end: 0;
					margin-inline-start: 0;
				}
			}
			a.brand {
				flex-direction: column;
				img {
					margin-inline-end: 0;
					margin-inline-start: 0;
					margin-block-end: 1em;
				}
			}
		}
		&.branding-mobile-layout-vertical-reverse a.brand {
			flex-direction: column-reverse;
			img {
				margin-inline-end: 0;
				margin-inline-start: 0;
				margin-block-start: 1em;
			}
		}
	}
}
.site-header-row-center-column .brand:not(.has-logo-image) .site-title-wrap {
    text-align: center;
}