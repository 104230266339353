
/*--------------------------------------------------------------
## Dropdown navigation for header Menus
--------------------------------------------------------------*/
.header-navigation ul ul.sub-menu, .header-navigation ul ul.submenu {
	display: none;
	position: absolute;
	top: 100%;
	flex-direction: column;
	background: #fff;
	margin-inline-start: 0;
	box-shadow: 0 2px 13px rgba(0,0,0,.1);
	z-index: 1000;
}
.header-navigation ul ul.quadmenu-row {
	flex-direction: row;
}
// Hide divider for last item.
.header-navigation ul ul.sub-menu > li:last-child, .header-navigation ul ul.submenu > li:last-child {
	border-bottom:0;
}
.header-navigation ul ul.sub-menu.sub-menu-edge {
	left: auto;
	right: 0;
}
.header-navigation ul ul ul.sub-menu.sub-menu-edge {
	left: auto;
	right: 100%;
}
// Handle Reveal Animation
.header-navigation[class*="header-navigation-dropdown-animation-fade"] {
	ul ul.sub-menu, ul ul.submenu {
		opacity: 0;
		visibility: hidden;
		transform: translate3d(0, 0, 0);
		transition: all 0.2s ease;
		display: block;
		clip: rect(1px, 1px, 1px, 1px);
		height: 1px;
		overflow: hidden;
	}
	ul li:hover>ul, ul li.menu-item--toggled-on>ul, ul li:not(.menu-item--has-toggle):focus>ul {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0, 0, 0);
		clip: auto;
		height: auto;
		overflow: visible;
	}
}
.header-navigation.header-navigation-dropdown-animation-fade-up {
	ul ul.sub-menu, ul ul.submenu {
		transform: translate3d(0, 10px, 0);
	}
}
.header-navigation.header-navigation-dropdown-animation-fade-down {
	ul ul.sub-menu, ul ul.submenu {
		transform: translate3d(0, -10px, 0);
	}
}
/*--------------------------------------------------------------
## Submenu Toggles for Header Menus
--------------------------------------------------------------*/
.site .nav--toggle-sub .dropdown-nav-special-toggle {
    position: absolute !important;
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;
	margin-inline-end: -0.1em !important;
    border: 0 !important;
	width: 0.9em;
	top:0;
	bottom: 0;
    height: auto;
	border-radius: 0;
	box-shadow: none !important;
    background: transparent !important;
	display: block;
	left: auto;
	right:calc( 1.4em / 2 );
	pointer-events: none;
}
.site .nav--toggle-sub .sub-menu .dropdown-nav-special-toggle {
	width: 2.6em;
	margin: 0 !important;
	right:0;
}
.nav--toggle-sub .dropdown-nav-special-toggle:focus {
    z-index: 10;
}
.nav--toggle-sub .dropdown-nav-toggle {
	display: block;
	background: transparent;
	position: absolute;
	right: 0;
	top: 50%;
	width: 0.7em;
	height: 0.7em;
	font-size: inherit;
	width: 0.9em;
	height: 0.9em;
	font-size: 0.9em;
	display: inline-flex;
	line-height: inherit;
	margin: 0;
	padding: 0;
	border: none;
	border-radius: 0;
	transform: translateY(-50%);
	overflow: visible;
}
.nav--toggle-sub ul ul .dropdown-nav-toggle {
	right: 0.2em;
}

.nav--toggle-sub ul ul .dropdown-nav-toggle .thebase-svg-iconset {
	transform: rotate(-90deg);
}
.nav--toggle-sub li.menu-item-has-children {
	position: relative;
}
.nav-drop-title-wrap {
	position: relative;
	padding-inline-end: 1em;
	display: block;
}
/*
* If the dropdown toggle is active with JS, then
* we'll take care of showing the submenu with JS.
*/
.nav--toggle-sub li:hover > ul,
.nav--toggle-sub li.menu-item--toggled-on > ul,
.nav--toggle-sub li:not(.menu-item--has-toggle):focus > ul {
	display: block;
}

/*
* "focus-within" is an alternative to focus class for
* supporting browsers (all but IE/Edge) for no-JS context
* (e.g. AMP) See https://caniuse.com/#feat=css-focus-within
*
* This selector needs to stay separated, otherwise submenus
* will not be displayed with IE/Edge.
*/
.nav--toggle-sub li:not(.menu-item--has-toggle):focus-within > ul {
	display: block;
}
// This makes the dropdowns work a little better on a touch device.
@media ( hover: none ) {
	.site .nav--toggle-sub .menu-item--has-toggle:not(.menu-item--toggled-on) .dropdown-nav-special-toggle {
		left: 0;
		right:0 !important;
		margin-inline-end: 0 !important;
		width: 100%;
		pointer-events: all;
	}
	.site .nav--toggle-sub .menu-item--has-toggle:not(.menu-item--toggled-on) > a {
		pointer-events: none;
	}
}
.rtl .nav--toggle-sub .dropdown-nav-toggle {
	right: auto;
	left: -15px;
}
.rtl .primary-menu-container > ul > li.menu-item > .dropdown-nav-special-toggle {
    right: auto;
    left: calc(1.4em / 2);
}
.rtl .site .nav--toggle-sub .sub-menu .dropdown-nav-special-toggle {
    left: 0;
    right: auto;
}
.rtl .nav--toggle-sub ul ul .dropdown-nav-toggle .thebase-svg-iconset {
    transform: rotate(
-270deg);
}
.rtl .header-navigation ul ul ul.sub-menu, .rtl .header-navigation ul ul ul.submenu {
    right: 100%;
    left: auto;
}
.rtl  .nav-drop-title-wrap {
    padding-inline-start: 1em;
    padding-inline-end: 0;
}
.site .nav--toggle-sub .thebase-menu-mega-enabled .sub-menu .dropdown-nav-special-toggle {
    display: none;
}
/*--------------------------------------------------------------
## Header navigation top item styling
--------------------------------------------------------------*/
.header-navigation-style-underline-fullheight, .header-navigation-style-fullheight {
	height: 100%;
    align-items: center;
	.header-menu-container {
		height: 100%;
		align-items: center;
		> ul {
			height: 100%;
			align-items: center;
			> li {
				display: flex;
				height: 100%;
				align-items: center;
				> a {
					display: flex;
					height: 100%;
					align-items: center;
				}
			}
		}
	}
}
.header-navigation[class*="header-navigation-style-underline"] {
	.header-menu-container{ 
		> ul > li {
			> a {
				position:relative;
				&:after {
					content: '';
					width: 100%;
					position: absolute;
					top: -2px;
					height: 2px;
					right: 50%;
					background: currentColor;
					transform: scale(0, 0) translate(-50%, 0);
					transition: transform .2s ease-in-out, color .0s ease-in-out;
				}
				&:hover:after {
					transform: scale(1, 1) translate(50%, 0);
				}
			}
			&.current-menu-item, &.current-page-item {
				> a:after {
					transform: scale(1, 1) translate(50%, 0);
				}
			}
		}
	}
}

/*--------------------------------------------------------------
## Header navigation menus
--------------------------------------------------------------*/
.header-navigation, .header-menu-container {
	display: flex;
}
.header-navigation li.menu-item > a {
	display: block;
	width: 100%;
	text-decoration: none;
	color: var(--global-palette1);
	transition: all .2s ease-in-out;
}
.header-navigation li.menu-item > a:hover,
.header-navigation li.menu-item > a:focus {
	color: var(--global-palette-highlight);
}
.header-navigation ul.sub-menu, .header-navigation ul.submenu {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}
.header-navigation ul li.menu-item > a {
	padding: 0.6em 0.5em;
}
.header-navigation ul ul li.menu-item > a {
	padding: 1.8em;
}
.header-navigation ul ul li.menu-item > a {
	width: 200px;
}
.header-navigation ul ul ul.sub-menu, .header-navigation ul ul ul.submenu {
	top: 0;
	left: 100%;
	min-height: 100%;
}
.header-navigation .menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
}
/*--------------------------------------------------------------
## Trigger
--------------------------------------------------------------*/
.menu-toggle-open {
	display: flex;
	background:transparent;
	align-items: center;
	box-shadow: none;
	.menu-toggle-label {
		padding-inline-end: 5px;
	}
	.menu-toggle-icon {
		display: flex;
	}
	.menu-toggle-label:empty {
		padding-inline-end: 0px;
	}
	&:hover, &:focus {
		border-color: currentColor;
		background: transparent;
		box-shadow: none;
	}
	&.menu-toggle-style-default {
		border:0;
	}
}
.site .menu-toggle-open {
	box-shadow: none;
}
/*--------------------------------------------------------------
## Mobile Menu
--------------------------------------------------------------*/
.mobile-navigation {
	width: 100%;
}
.mobile-navigation a {
	display: block;
	width: 100%;
	text-decoration: none;
	padding: 0.6em 0.5em;
}
.mobile-navigation ul {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}
.drawer-nav-drop-wrap {
	display: flex;
	position: relative;
	a {
		color:inherit;
	}
	.drawer-sub-toggle {
		background: transparent;
		color: inherit;
		padding: 0.5em 0.7em;
		display: flex;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		line-height: normal;
	}
	.drawer-sub-toggle[aria-expanded="true"] svg {
		transform: rotate(180deg);
	}
}
.mobile-navigation.drawer-navigation-parent-toggle-true .drawer-nav-drop-wrap .drawer-sub-toggle {
    position: absolute;
    left: 0;
    z-index: 10;
    top: 0;
    bottom: 0;
    justify-content: flex-end;
    width: 100%;
}

.mobile-navigation ul ul {
	padding-inline-start: 1em;
	transition: all 0.2s ease-in-out;
}
.mobile-navigation ul.has-collapse-sub-nav ul.sub-menu {
    display: none;
}
body[class*='amp-mode-'] .mobile-navigation ul ul {
    display: block;
}
.mobile-navigation ul ul ul ul ul ul {
    padding-inline-start: 0;
}
.mobile-navigation ul.has-collapse-sub-nav .sub-menu.show-drawer {
	display: block;
}
/*--------------------------------------------------------------
## Drawer
--------------------------------------------------------------*/
.popup-drawer {
	position: fixed;
	display: none;
	top: 0;
    bottom: 0;
	left: -99999rem;
    right: 99999rem;
	transition: opacity 0.25s ease-in, left 0s 0.25s, right 0s 0.25s;
	z-index: 100000;
	.drawer-overlay {
		background-color: rgba(0,0,0,.4);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}
	.drawer-inner {
		width: 100%;
		transform: translateX(100%);
		max-width:90%;
		right:0;
		top: 0;
		overflow: auto;
		background: var(--global-palette9);
		color:#000;
		bottom: 0;
		opacity: 0;
		position: fixed;
		box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.4s cubic-bezier(0.77,0.2,0.05,1.0);
	}
	.drawer-header {
		padding: 0 1.5em;
		display: flex;
		justify-content: flex-end;
		// Fixes safair bug.
		min-height: calc( 1.2em + 24px );
		.drawer-toggle {
			background: transparent;
			border: 0;
			font-size: 24px;
			line-height: 1;
			padding: 0.6em 0.15em;
			color: var(--global-palette1);
			display: flex;
			box-shadow: none;
			border-radius: 0;
			&:hover {
				box-shadow: none;
			}
		}
	}
	.drawer-content {
		padding: 0 1.5em 1.5em;
	}
}
.popup-drawer-layout-sidepanel.popup-drawer-side-left {
	.drawer-inner {
		transform: translateX(-100%);
		right:auto;
		left:0;
	}
}
@media screen and ( max-width: 1024px){
	.popup-drawer{
		.drawer-inner{
			max-width: 60%;
		}
	}		
}
@media screen and ( max-width: 479px){
	.popup-drawer{
		.drawer-inner{
			max-width: 90%;
		}
	}	 
}
@media screen and ( max-width: $tablet-minus-query) {
.showing-popup-drawer-from-right.animate-body-popup #inner-wrap, .showing-popup-drawer-from-right.animate-body-popup #colophon, .showing-popup-drawer-from-right.animate-body-popup .site-header-row {
	transform: translateX(-200px);
}
.showing-popup-drawer-from-left.animate-body-popup #inner-wrap, .showing-popup-drawer-from-left.animate-body-popup #colophon, .showing-popup-drawer-from-left.animate-body-popup .site-header-row {
    transform: translateX(200px);
}
.animate-body-popup #inner-wrap, .animate-body-popup .site-header-row, .animate-body-popup #colophon {
	transform: none;
    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
}
}
.popup-drawer-animation-scale.popup-drawer-layout-fullwidth .drawer-inner {
	transform: scale(0);
	transform-origin: top right;
    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.4s cubic-bezier(0.77,0.2,0.05,1.0);
}
.popup-drawer-animation-scale.popup-drawer-layout-fullwidth.popup-drawer-side-left .drawer-inner {
	transform-origin: top left;
}
.popup-drawer-animation-scale.popup-drawer-layout-fullwidth.active .drawer-inner {
    transform: scale(1);
}
#mobile-drawer.popup-drawer-animation-slice.popup-drawer-layout-fullwidth .drawer-overlay, #mobile-drawer.popup-drawer-animation-slice.popup-drawer-layout-fullwidth .drawer-inner {
    background: transparent;
}

.popup-drawer .drawer-header .drawer-toggle {
    width: 1em;
    position: relative;
    height: 1em;
    box-sizing: content-box;
	font-size: 24px;
}
.drawer-toggle .toggle-close-bar {
    width: 0.75em;
    height: 0.08em;
    background: currentColor;
    transform-origin: center center;
    position: absolute;
    margin-block-start: -0.04em;
	opacity: 0;
	border-radius:0.08em;
    left: 50%;
    margin-inline-start: -0.375em;
    top: 50%;
	transform: rotate(45deg) translateX(-50%);
	transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
	transition-delay: 0.2s;
}
.drawer-toggle .toggle-close-bar:last-child {
	transform: rotate(-45deg) translateX(50%);
}
.popup-drawer.active {
	.drawer-toggle .toggle-close-bar {
		transform: rotate(45deg);
		opacity: 1;
	}
	.drawer-toggle .toggle-close-bar:last-child {
		transform: rotate(-45deg);
		opacity: 1;
	}
}
.popup-drawer .drawer-content.content-valign-middle {
    min-height: calc( 100% - ( 1.2em + 24px ) );
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-block-end: calc( 1.2em + 24px );
	max-height: calc( 100% - ( 1.2em + 24px ));
    overflow: auto;
}
.popup-drawer .drawer-content.content-valign-bottom {
    min-height: calc( 100% - ( 1.2em + 24px ) );
    display: flex;
	justify-content: flex-end;
    flex-direction: column;
    padding-block-end: calc( 1.2em + 24px );
	max-height: calc( 100% - ( 1.2em + 24px ));
    overflow: auto;
	.site-header-item-mobile-navigation {
		display: block;
		overflow: auto;
		max-height: calc( 100% - ( 1.2em + 24px ));
	}
}
.popup-drawer .drawer-content.content-align-center {
    text-align: center;
}
.popup-drawer .drawer-content.content-align-center .site-header-item {
    justify-content: center;
}
.popup-drawer .drawer-content.content-align-right {
    text-align: right;
}
.popup-drawer .drawer-content.content-align-right .site-header-item {
    justify-content: flex-end;
}
.popup-drawer-layout-fullwidth.popup-drawer-animation-slice {
	.drawer-inner {
		transform: none;
	}
	.pop-slice-background {
		transform-origin: center;
		transform: none;
		position: absolute;
		display: flex;
		flex-flow: column;
		align-items: stretch;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}
	.pop-portion-bg {
		flex: 1 1 100%;
		background: rgba(9,12,16,0.97);
		transform: translateX(-100%);
		transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
	}
	.pop-portion-bg:nth-child(odd) {
		transform: translateX(100%);
	}
	.drawer-content {
		transform: translateY(20px);
		opacity: 0;
		transition: all 0.4s ease;
	}
}
.popup-drawer-layout-fullwidth.popup-drawer-animation-slice.active {
	.pop-portion-bg {
		transform: translateX(0);
	}
	.drawer-content {
		transform: translateY(0px);
		opacity: 1;
		transition: all 0.4s ease;
		transition-delay: 0.2s;
	}
}

@media screen and ( max-width: $tablet-minus-query) {
	.popup-drawer-layout-sidepanel.popup-mobile-drawer-side-right {
		.drawer-inner {
			transform: translateX(100%);
			right:0;
			left:auto;
		}
	}
	.popup-drawer-layout-sidepanel.popup-mobile-drawer-side-left {
		.drawer-inner {
			transform: translateX(-100%);
			right:auto;
			left:0;
		}
	}
}
body.admin-bar .popup-drawer {
	top: 46px;
	.drawer-inner { top: 46px; }
}
@media screen and ( min-width: 783px) {
	body.admin-bar .popup-drawer {
		top: 32px;
		.drawer-inner { top: 32px; }
	}
}
.popup-drawer.show-drawer {
    display: block;
}
.popup-drawer.active {
	left: 0;
    opacity: 1;
	right: 0;
	transition: opacity 0.25s ease-out;
	.drawer-inner {
		opacity:1;
		transform: translateX(0%);
	}
	.drawer-overlay {
		opacity:1;
		cursor: pointer;
	}
}
body[class*="showing-popup-drawer-"] {
    overflow: hidden;
}
.secondary-navigation .secondary-menu-container > ul {

	> li.menu-item {
		> a{ 
			position: relative;

				&::after {
					background: var(--global-gray-500);
					width: 1px;
					height: 12px;
					position: absolute;
					content: "";
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}
		&:first-child {
			a { 
				padding-inline-start: 0;
			}	
		}				
		&:last-child {
			a { 
				padding-inline-end: 0;
				&:after {
					content: none;
				}
			}
		}
	}
}